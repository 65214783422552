<template>
	<div class="container news-items">
		<div class="news-item__header mb-20">
			<h3>{{ $t("registerOfActiveTrades") }}</h3>
			<router-link to="/active-trades" class="color-text-light d-flex align-center">
				<span>{{ $t("allActiveTrades") }}</span>
				<img class="ml-10" src="@/assets/icons/aroow-right.svg" alt />
			</router-link>
		</div>
		<div class="section__body">
			<div class="table-block" :class="isMobileMedium ? 'mb-30' : 'mb-60'">
				<table>
					<thead>
						<tr class="customClassForActiveLotsTableTr">
							<th class="text-bold">{{ $t("lotNumber") }}</th>
							<th class="text-bold">{{ $t("numberOffer") }}</th>
							<th class="text-bold">{{ $t("productName") }}</th>
							<th class="text-bold">{{ $t("TNVEDCode") }}</th>
							<th class="text-bold">{{ $t("count") }}</th>
							<th class="text-bold">{{ $t("unitMeasurement") }}</th>
							<!-- <th class="text-bold">{{ $t("minimumAmount") }}</th>
							<th class="text-bold">{{ $t("maxAmount") }}</th> -->
							<!-- <th class="text-bold">{{ $t("sertificate") }}</th> -->
							<th class="text-bold">{{ $t("startDate") }}</th>
							<th class="text-bold">{{ $t("expirationDate") }}</th>
						</tr>
					</thead>
					<tbody class="bg-white">
						<template v-if="list && Array.isArray(list) && list.length">
							<tr class="text-center" v-for="item in list">
								<td>{{ item.lotNumber || "-" }}</td>
								<td>{{ item.offerNumber || "-" }}</td>
								<td>{{ item.productName || "-" }}</td>
								<td>{{ item.lotTnved || item.lotEnkt || "-" }}</td>
								<td>{{ item.quantity || "-" }}</td>
								<td>{{ item.measureName || "-" }}</td>
								<!-- <td>{{ item.minPart || "-" }}</td>
								<td>{{ item.maxPart || "-" }}</td> -->
								<!-- <td width="200">
									{{ item.isCertificate ? $t("certificated") : $t("notCertificated") }}
								</td> -->
								<td width="180">
									{{ item.beginDate ? $moment(item.beginDate).format("DD.MM.YYYY HH:mm:ss") : "-" }}
								</td>
								<td width="180">
									{{ item.endDate ? $moment(item.endDate).format("DD.MM.YYYY HH:mm:ss") : "-" }}
								</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td colspan="8">{{ $t("noData") }}</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import AppText from "../../../../components/shared-components/AppText";

export default {
	name: "HomeActiveLots",
	components: { AppText },
	data() {
		return {
			list: [],
		};
	},
	async created() {
		await this.load();
	},
	methods: {
		async load(page, take) {
			try {
				this.$store.commit("setLoadingStatus", true);
				let params = {
					skip: 0,
					take: 10,
				};
				const response = await this.$api.get("/api-shop/LotRequest/GetLotsInTrade", { params });
				this.list = response.data.result.result;
			} finally {
				this.$store.commit("setLoadingStatus", false);
			}
		},
	},
};
</script>

<style scoped lang="scss">
.news-item__header {
	display: flex;
	justify-content: space-between;
	h3 {
		font-size: 32px;
	}
}
@media screen and (max-width: 768px) {
	.news-item__header {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0;
		h3 {
			font-size: 24px;
		}
	}
}
table-block table th {
	border: 1px solid #e5eff5 !important;
}
.customClassForActiveLotsTableTr {
	background-color: #fff;
	border-bottom: 1px solid #e5eff5;
}
</style>

