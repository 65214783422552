<template>
	<section class="section bg-primary">
		<div class="container">
			<div class="section__wrapper">
				<div class="home-item__header">
					<!-- <pre>{{ data }}</pre> -->
					<h3>{{ $t("news") }}</h3>
					<router-link to="/news" class="color-text-light d-flex align-center">
						<span>{{ $t("allNews") }}</span>
						<img class="ml-10" src="@/assets/icons/aroow-right.svg" alt />
					</router-link>
				</div>
				<div class="section__body">
					<BlockWrap
						:count="isMobileMedium ? 1 : isMobile ? 2 : isDesktopSmall ? 3 : 4"
						offset-y="16"
						offset-x="16"
					>
						<template v-for="item in data">
							<template v-if="item.type === 2">
								<div>
									<div class="card__photo mb-30" style="height: 210px">
										<img
											src="../../../../assets/icons/Notification.svg"
											style="height: 80%; display: block; margin: auto 0"
											alt="Notification"
										/>
									</div>
									<div>
										<AppText class="mb-20 color-text-light" size="14" line-height="18" weight="400">
											{{ item.createDate.slice(0, 10) }}
										</AppText>
									</div>
									<div>
										<AppText
											:class="[{ colorWhite: !!cardIcon }, { defaultColor: instructions }]"
											size="18"
											line-height="21"
											weight="500"
											max-lines="2"
											class="font_Mmedium"
										>
											<p v-html="item.mavzu[`${$store.state.clng}`]"></p>
										</AppText>
									</div>
								</div>
							</template>
							<template v-else>
								<router-link :key="item.id" :to="`/news/${item.id}`">
									<AppNewsCard
										:title="item.mavzu[`${$store.state.clng}`]"
										:image="getImgUrl(item.photos)"
										:date="item.newsDate.slice(0, 10)"
										btn
									/>
								</router-link>
							</template>
						</template>
					</BlockWrap>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import AppText from "@/components/shared-components/AppText";
import BlockWrap from "@/components/shared-components/BlockWrap";
import AppNewsCard from "@/components/shared-components/AppNewsCard";

export default {
	name: "HomeVideoInstruction",
	components: { AppNewsCard, BlockWrap, AppText },
	props: {
		cardIcon: {
			default: false,
		},
		instructions: {
			default: false,
		},
	},
	data() {
		return {
			data: null,
			query: {
				skip: 0,
				take: 4,
			},
			news: [
				{
					image: require("@/assets/images/post.jpg"),
					title: "Прозрачная схема работы",
					date: "20.05.2021",
				},
				{
					image: require("@/assets/images/post.jpg"),
					title: "Прозрачная схема работы",
					date: "20.05.2021",
				},
				{
					image: require("@/assets/images/post.jpg"),
					title: "Прозрачная схема работы",
					date: "20.05.2021",
				},
				{
					image: require("@/assets/images/post.jpg"),
					title: "Прозрачная схема работы",
					date: "20.05.2021",
				},
			],
			adminUrl: process.env.VUE_APP_BASE_URL_DOMAIN,
		};
	},
	methods: {
		load() {
			this.$api
				.get(`/api-client/Client/GetAllNews`, { params: this.query })
				.then(
					(response) => {
						const res = response.data;
						if (res && res.result) {
							this.data = res.result.data;
						} else {
							this.$notification.error(res.error.message);
						}
					},
					(error) => {
						this.$notification.error(error);
					}
				)
				.finally(() => {
					this.loading = false;
				});
		},
		getImgUrl(imgUrl) {
			if (imgUrl != null) {
				let separator = imgUrl.includes(";") ? ";" : "|";
				let img = Array.isArray(imgUrl.split(separator)[0])
					? imgUrl.split(separator)[0][0]
					: imgUrl.split(separator)[0];
				return this.adminUrl + img.replaceAll(separator, "").replaceAll("null", "");
			} else {
				return imgUrl;
			}
		},
	},
	created() {
		this.load();
	},
};
</script>

<style lang="scss" scoped>
.section__wrapper {
	display: flex;
	flex-direction: column;
	gap: 30px;
}
.home-item__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	h3 {
		font-size: 32px;
	}
}
@media screen and (max-width: 768px) {
	.section__wrapper {
		gap: 20px;
	}
	.section__top {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		align-content: flex-start;
		h3 {
			text-align: start;
		}
	}
	.home-item__header {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0;
		margin: 0;
		h3 {
			font-size: 24px;
		}
	}
}
</style>

