<template>
	<div>
		<div class="card">
			<div class="card__photo mb-30" :class="{ overlay: cardIcon }">
				<img :src="coverImage" v-if="cardIcon" :alt="title" />
				<img :src="image" :alt="title" v-else />
				<!-- <div class="card__photo-icon"
             :to="link"
             @click="$emit('openVideo')"
             v-if="cardIcon">
          <img src="../../assets/icons/play.svg" alt="">
        </div> -->
			</div>
			<div>
				<AppText class="mb-20 color-text-light" size="14" line-height="18" weight="400">
					{{ date }}
				</AppText>
				<AppText
					:class="[{ colorWhite: cardIcon }, { defaultColor: instructions }]"
					size="18"
					line-height="21"
					weight="500"
					max-lines="2"
					class="font_Mmedium"
				>
					{{ title }}
				</AppText>
				<!-- <a :href="downloadPath"
           class="d-flex download"
           v-if="downloadTitle"
        >
          <img src="../../assets/icons/document.svg" class="mr-10" alt="">
          <AppText size="14"
                   line-height="18"
                   weight="500"
                   class="color-link"
          >
            {{ downloadTitle }}
          </AppText>
        </a> -->
			</div>
		</div>
	</div>
</template>

<script>
import AppText from "@/components/shared-components/AppText";
import AppButton from "@/components/shared-components/AppButton";
import "../../assets/styles/components/app-card.scss";

export default {
	name: "AppNewsCard",
	components: { AppButton, AppText },
	props: {
		cover: {
			type: String,
			default: "",
		},
		url: {
			type: String,
			default: "",
		},
		image: {
			type: String,
			default: "",
		},
		title: {
			type: String,
			default: "",
		},
		date: {
			type: String,
			default: "",
		},
		cardIcon: {
			type: Boolean,
			default: false,
		},
		btn: {
			type: Boolean,
			default: false,
		},
		link: {
			type: String,
			default: "",
		},
		instructions: {
			type: Boolean,
			default: false,
		},
		downloadTitle: {
			type: String,
			default: "",
		},
		downloadPath: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			baseUrl: process.env.VUE_APP_BASE_URL_DOMAIN,
		};
	},
	computed: {
		coverImage() {
			return this.baseUrl + this.cover;
		},
	},
};
</script>

<style lang="scss" scoped>
.card {
	height: auto;
	padding: 0 !important;
	margin: 0 !important;
	background-color: transparent;
	text-align: start !important;
	border-radius: 0;
}
.card__photo {
	height: 210px;
}

.card__photo img {
	width: 100%;
	height: 100%;
	margin-bottom: 30px;
	border-radius: 12px !important;
}
</style>
