<template>
	<div class="container">
		<div class="card-services">
			<router-link
				:to="item.link"
				:is="!item.ready ? 'span' : 'router-link'"
				class="card-services__items"
				:class="{ readyClass: item.ready }"
				v-for="(item, idx) in services"
				:key="idx"
			>
				<div class="card-services__item">
					<div class="service__item-icon">
						<img :src="item.image" alt="image" />
					</div>
					<div style="font-size: 1.1rem; text-align: center">
						{{ $t(item.title) }}
					</div>
					<div v-if="!item.ready" style="font-size: 0.8rem; color: #0c8acb">
						{{ $t("developing") }}
					</div>
				</div>
			</router-link>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			services: [
				{
					title: "shop",
					content: "servicesText1",
					image: require("@/assets/images/svg/services/online-shop.svg"),
					link: "/product-list",
					ready: true,
				},

				{
					title: "anAuction",
					content: "servicesText2",
					image: require("@/assets/images/svg/services/auction.svg"),
					link: "/auction",
					ready: false,
				},

				{
					title: "selectionOfTheBestOffers",
					content: "servicesText3",
					image: require("@/assets/images/svg/services/randomText.svg"),
					link: "/selection",
					ready: false,
				},

				{
					title: "tender",
					content: "servicesText4",
					image: require("@/assets/images/svg/services/tender.svg"),
					link: "/tender",
					ready: false,
				},
			],
		};
	},
};
</script>
<style lang="scss" scoped>
.card-services {
	border-radius: 12px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 30px;
	span {
		transform: scale(0.85);
	}
}

.card-services__items {
	border-radius: 4px;
	padding: 16px;
	cursor: pointer;
	transition: transform 0.4s ease;
	// transform: scale(0.85);
}

// .card-services__items:hover {
// 	transform: translateY(-8px) scale(0.85);
// 	box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.3);
// }
.card-services__items:hover {
	position: relative;
	bottom: 0px;
}
.card-services__item {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
}
.service__item-icon {
	width: 60px;
	height: auto;
}
.service__item-icon img {
	width: 100%;
	height: auto;
}
@media screen and (max-width: 960px) {
	.card-services {
		gap: 15px;
	}
	.card-services__items {
		padding: 8px;
	}
	.service__item-icon {
		width: 40px;
	}
}
@media screen and (max-width: 720px) {
	.card-services {
		grid-template-columns: repeat(2, 1fr);
		gap: 15px;
	}
	.service__item-icon {
		width: 30px;
	}
}
.readyClass {
	background: rgba(255, 255, 255, 1);
	border: 1px solid #7f8ea5;
	border-radius: 10px;
}
.readyClass:hover {
	box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.1);
}
</style>

