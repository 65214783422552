<template>
	<div class="final-timer">
		<div class="finaltext">{{ $t("timerText") }}</div>
		<div class="timer">
			<div>
				<div class="value">{{ daysLeft }}</div>
				<div>
					{{
						daysLeft === 1 ? $t("day3") : daysLeft > 4 ? $t("day4") : daysLeft < 5 ? $t("day2") : $t("day3")
					}}
				</div>
			</div>
			<div>
				<div class="value">{{ hoursLeft }}</div>
				<div>{{ $t("hours") }}</div>
			</div>
			<div>
				<div class="value">{{ minutesLeft }}</div>
				<div>{{ $t("minutes") }}</div>
			</div>
			<div>
				<div class="value">{{ secondsLeft }}</div>
				<div>{{ $t("seconds") }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		currentDate: Math.round(new Date().getTime() / 1000),
		endDate: new Date("2023-07-03 9:00").getTime() / 1000,
		difference: null,
		interval: null,
	}),
	created() {
		this.difference = this.endDate - this.currentDate;
		this.dateCheck();
	},
	methods: {
		start() {
			this.interval = setInterval(() => {
				this.difference -= 1;
			}, 1000);
		},
		check() {
			if (this.difference < 1) {
				clearInterval(this.interval);
			}
		},
		dateCheck() {
			if (this.difference > 1) {
				this.start();
			} else {
				this.difference = 0;
			}
		},
	},
	computed: {
		daysLeft() {
			return Math.floor(this.difference / (3600 * 24)) || 0;
		},
		hoursLeft() {
			return Math.floor((this.difference % (3600 * 24)) / 3600) || 0;
		},
		minutesLeft() {
			return Math.floor(this.difference / 60) % 60 || 0;
		},
		secondsLeft() {
			return this.difference % 60 || 0;
		},
	},
};
</script>

<style scoped lang="scss">
.finaltext {
	font-weight: 600;
}
.final-timer {
	font-size: 1.5rem;
	text-align: center;
	color: #ffffff;
	margin: 20px 0 0 0;
	.timer {
		margin-top: 20px;
		display: flex;
		justify-content: center;
		& > div {
			font-size: 1.1rem;
			margin: 0 10px;
			width: 95px;
			aspect-ratio: 1/1;
			border-radius: 25px;
			border: 2px solid #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			.value {
				font-weight: 600;
				font-size: 2.5rem;
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.final-timer {
		font-size: 12px;
		.timer {
			margin-top: 15px;
			& > div {
				margin: 0 7px;
				font-size: 11px;
				width: 50px;
				border-radius: 13px;
				.value {
					font-size: 15px;
				}
			}
		}
	}
}
</style>
