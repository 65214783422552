<template>
	<div>
		<div class="container news-items">
			<div class="news-item__header">
				<h3>{{ $t("suppliers") }}</h3>
				<router-link to="/suppliers" class="color-text-light d-flex align-center">
					<span>{{ $t("allSuppliers") }}</span>
					<img class="ml-10" src="@/assets/icons/aroow-right.svg" alt />
				</router-link>
			</div>
			<MainSwiper :data="allSuppliers" :breakpoints="breakpoints">
				<template #default="{ item }">
					<div class="swiper-card">
						<div class="swiper-card__img">
							<template v-if="item.photo">
								<img :src="item.photo" alt="" />
							</template>
							<template v-else>
								<img src="@/assets/images/svg/logo-grey.svg" alt="" />
							</template>
						</div>
						<div class="swiper-card__content">
							<h3>{{ item.companyName[lang] }}</h3>
							<div>
								<p>{{ item.comment }}</p>
							</div>
							<h4>{{ $t("voted") }}:</h4>
						</div>
						<div class="swiper-card__percentage">
							<div>
								<div :class="item.zaProcent > 0 ? 'percentage-blue' : 'hidden'"></div>
								<div :class="item.protivProcent > 0 ? 'percentage-red' : 'hidden'"></div>
								<div :class="item.zaProcent > 0 || item.protivProcent > 0 ? 'hidden' : ''"></div>
							</div>
							<div>
								<span>{{ item.zaProcent }}%</span>
								<span>{{ item.protivProcent }} %</span>
							</div>
						</div>
						<div class="swiper-card__button">
							<template v-if="item.hasVoted">
								<div v-if="item.zaProcent > 0" style="color: blue; text-align: center">
									Вы проголосовали {{ item.zaProcent > 0 ? $t("for") : "" }}
								</div>
								<div v-if="item.protivProcent > 0" style="color: red; text-align: center">
									Вы проголосовали {{ item.protivProcent > 0 ? $t("against") : "" }}
								</div>
							</template>
							<template v-else>
								<button @click="handleVote(1, item.id)">
									<img
										src="@/assets/images/ok.svg"
										width="20"
										height="20"
										class="mr-10"
										alt="not images"
									/>{{ $t("for") }}
								</button>
								<button @click="handleVote(2, item.id)">
									<img
										src="@/assets/images/dis_like.svg"
										width="20"
										height="20"
										class="mr-10"
										alt=""
									/>
									{{ $t("against") }}
								</button>
							</template>
						</div>
					</div>
				</template>
			</MainSwiper>
			<div v-if="allSuppliers.length > 0" class="swiper-slide__button">
				<div class="swiper-button-prev">
					<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M7 1L1 7L7 13"
							stroke="#8E9195"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</div>
				<div class="swiper-button-next">
					<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M1 13L7 7L1 1"
							stroke="#8E9195"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import LanguageService from "../../../../services/LanguageService";
import MainSwiper from "../../../ui/MainSwiper.vue";

export default {
	components: {
		MainSwiper,
	},
	data() {
		return {
			breakpoints: {
				1: {
					slidesPerView: 1,
					spaceBetween: 20,
				},
				640: {
					slidesPerView: 2,
					spaceBetween: 20,
				},
				768: {
					slidesPerView: 3,
					spaceBetween: 20,
				},
				1024: {
					slidesPerView: 4,
					spaceBetween: 20,
				},
			},
			data: null,
			allSuppliers: [],
			lang: LanguageService.getLanguage(),
		};
	},
	methods: {
		getAllCompany() {
			let params = {
				skip: 0,
				take: 15,
			};
			this.$api.get("/api-client/Client/GetAllCompany", { params }).then((response) => {
				this.allSuppliers = response.data.result.data;
			});
		},

		langSetter() {
			this.lang = this.$i18n.locale;
		},
		handleVote(status, id) {
			let params = {
				id,
				status,
			};
			if (this.$store.state.userInfo.tin) {
				this.$api.post("/api-client/Client/AddUpdateCompanyVoute", params).then((response) => {
					this.$notification.success(this.$i18n.t("notification.success"));
					this.getAllCompany();
				});
			} else {
				this.$notification.error(this.$i18n.t("authForVote"));
			}
		},
	},
	created() {
		this.getAllCompany();
	},
};
</script>
<style lang="scss" scoped>
.news-items {
	display: flex;
	flex-direction: column;
	gap: 20px;
	overflow-x: hidden;
}
.news-item__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	h3 {
		font-size: 32px;
	}
}
.swiper-card {
	display: flex;
	flex-direction: column;
	padding: 30px 15px;
	gap: 20px;
	align-items: center;
	background: #fff;
	border-radius: 4px;
}
.swiper-card__img {
	width: 100px;
	height: 100px;
	margin: 0 auto;
	img {
		width: 100%;
		height: auto;
	}
}
.swiper-card__content {
	display: flex;
	flex-direction: column;
	min-height: 200px;
	justify-content: space-between;
	align-items: center;
	h3 {
		text-align: center;
	}
	div {
		display: flex;
		text-align: center;
		p {
			font-size: 14px;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
			color: #8e9195;
		}
	}
	h4 {
		font-size: 15px;
		color: #535353;
	}
}
.swiper-card__percentage {
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 10px;
	div:nth-child(1) {
		div {
			width: 100%;
			height: 15px;
			border-radius: 10px;
		}
	}
	div:nth-child(2) {
		display: flex;
		justify-content: space-between;
	}
}
.swiper-card__button {
	width: 100%;
	display: flex;
	height: 40px;
	gap: 8px;
	button {
		width: 50%;
		padding: 8px 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 6px;
		border-radius: 8px;
		font-weight: bold;
	}
	button:nth-child(1) {
		background: #39537a;
		color: white;
	}
	button:nth-child(2) {
		background: rgba(251, 97, 97, 0.1);
		color: red;
	}
}
.percentage-blue {
	background: linear-gradient(270deg, #0a67a2 -22.58%, #13b2e4 117.5%);
}
.percentage-red {
	background: linear-gradient(
		90deg,
		rgba(233, 47, 38, 0.5803571428571428) 0%,
		rgba(241, 63, 63, 0.9473039215686274) 50%,
		rgba(252, 69, 69, 1) 100%
	);
}
.hidden {
	display: none !important;
}
@media screen and (max-width: 768px) {
	.news-items {
		gap: 20px;
	}
	.news-item__header {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0;
		margin: 0;
		h3 {
			font-size: 24px;
		}
	}
	.swiper-card {
		padding: 25px 10px;
		gap: 12px;
	}
	.swiper-card__img {
		width: 50px;
		height: 50px;
		margin: 0 auto;
		img {
			width: 100%;
			height: auto;
		}
	}
	.swiper-card__content {
		min-height: 180px;
		h3 {
			font-size: 18px;
		}
		div {
			p {
				font-size: 12px;
			}
		}
		h4 {
			font-size: 12px;
		}
	}
	.swiper-card__button {
		button {
			height: 30px;
			gap: 8px;
		}
	}
}
.swiper-slide__button {
	position: relative;
	height: auto;
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: -10px;
	gap: 40px;
	div {
		z-index: 1;
		position: static;
		margin-top: 0;
		margin-bottom: 0;
		height: auto;
		background: #fff;
		height: 40px;
		width: 40px;
		border-radius: 100%;
		box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
	}
}
</style>
