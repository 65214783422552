<template>
	<div class="container">
		<div class="card">
			<div class="card-items">
				<h3>{{ $t("aboutPortal") }}</h3>
				<p>
					{{ $t("aboutPortalSubtitle") }}
				</p>
				<div class="cards-for-stat">
					<div v-for="(item, index) in numericStatistics" :key="index">
						<img :src="item.icon" :alt="item.title" />
						<div>
							<div class="customClassForInfo">
								<p>{{ $t(item.title) }}:</p>
								<p class="valueTextStats">
									{{ item.value }}
								</p>
							</div>
							<div v-if="item.goToShop" class="customClassForInfoRoute">
								<router-link to="/product-list">{{ $t("shop") }} &raquo;</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-item__iframes">
				<div>
					<iframe src="https://www.youtube.com/embed/PEEBJ1kgThw" frameborder="0" allowfullscreen></iframe>
				</div>
				<div>
					<iframe src="https://www.youtube.com/embed/KJJc785jRdw" frameborder="0" allowfullscreen></iframe>
				</div>
				<div>
					<iframe src="https://www.youtube.com/embed/0FWWHgDayv0" frameborder="0" allowfullscreen></iframe>
				</div>
				<div>
					<iframe src="https://www.youtube.com/embed/TySZ2LaJZvU" frameborder="0" allowfullscreen></iframe>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Stat from "@/assets/images/purchases/Stat.svg";
import Bank from "@/assets/images/purchases/Bank.svg";
import Users from "@/assets/images/purchases/Users.svg";
import Basket from "@/assets/images/purchases/Basket.svg";
import ClientService from "@/services/api/client-service";
export default {
	name: "HomePurchasingMethods",
	components: {},
	data() {
		return {
			numericStatistics: {},
		};
	},
	created() {
		this.getNumericStatistics();
	},
	methods: {
		getNumericStatistics() {
			ClientService.getNumericStatistics().then(
				(res) => {
					this.numericStatistics = res.data.result;
					const icons = [Stat, Bank, Users, Basket];
					let count = 0;
					let data = [];
					for (let k in this.numericStatistics) {
						data.push({
							title: k,
							value: this.numericStatistics[k],
							icon: icons[count],
						});
						count++;
					}
					let result = data.filter((e) => e.title === "participantsCount" || e.title === "productsCount");
					this.numericStatistics = result;
					result[1].goToShop = true;
				},
				(error) => console.log(error)
			);
		},
	},
};
</script>
<style lang="scss" scoped>
.customClassForInfo {
	display: flex;
	align-items: center;
}
.customClassForInfoRoute {
	font-size: 14px;
	color: #9bb5cc;
	text-align: left;
	text-indent: 15px;
}

p {
	text-align: justify;
	font-size: 18px;
	text-indent: 40px;
}
.cards-for-stat {
	margin: auto 0;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	.valueTextStats {
		font-size: 26px !important;
		font-weight: 600 !important;
	}
	& > div {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		// flex-direction: column;
		p {
			font-size: 20px !important;
			// margin-top: 10px;
			text-indent: 15px !important;
		}
	}
	img {
		display: block;
		border-radius: 10px;
		padding: 20px;
		height: 70px;
		background-color: #314564;
	}
}
.card {
	display: grid;
	border-radius: 4px;
	grid-template-columns: repeat(2, 2fr);
	padding: 30px;
	gap: 60px;
	box-shadow: 0px 10px 20px rgba(168, 222, 243, 0.3);
}
.card:hover {
	box-shadow: 0px 10px 20px rgba(35, 87, 155, 0.3);
}
.card-items:nth-child(1) {
	display: flex;
	flex-direction: column;
	gap: 20px;
	h3 {
		font-size: 32px;
	}
	align-items: flex-start;
}
.card-items:nth-child(2) {
	display: flex;
	flex-direction: column;
	div {
		display: flex;
		height: 100%;
		div:nth-child(1) {
			background: #314564;
			padding: 10px;
			max-width: 60px;
			height: 80%;
			border-radius: 12px;
			img {
				width: 100%;
				height: auto;
			}
		}
	}
}
.card-item {
	align-items: center;
	gap: 20px;
}
.card-item__border {
	border-bottom: 1px solid rgb(212, 212, 212);
}

.countersValue {
	font-size: 28px;
}
@media screen and (max-width: 1140px) {
	.card {
		grid-template-columns: repeat(1, 1fr);
		gap: 10px;
	}
	.card-items:nth-child(2) {
		div {
			display: flex;
			justify-items: center;
			align-content: center;
			justify-content: center;
			height: 100% !important;
			width: 100% !important;
			img {
				height: 30px !important;
				width: 100% !important;
			}
		}
	}
	.cards-for-stat {
		margin-bottom: 20px;
		.valueTextStats {
			font-size: 26px !important;
			font-weight: 600 !important;
		}
		& > div {
			margin: 10px 0;
			p {
				font-size: 16px !important;
				// margin-top: 10px;
				text-indent: 10px !important;
			}
		}
		img {
			border-radius: 5px;
			padding: 13px;
			height: 50px;
			background-color: #314564;
		}
	}
	.card-item {
		padding: 2px 0px 2px 0px;
	}
}
@media screen and (max-width: 750px) {
	.card-items {
		gap: 8px !important;
		p {
			font-size: 14px !important;
		}
		h3 {
			font-size: 24px !important;
		}
	}
	.card-item {
		padding: 0px 0px 6px 0px;
	}
	.cards-for-stat {
		margin-bottom: 5px;
		.valueTextStats {
			font-size: 20px !important;
			font-weight: 600 !important;
		}
		& > div {
			p {
				font-size: 14px !important;
				margin-top: 10px;
				text-indent: 6px !important;
			}
		}
		img {
			border-radius: 5px;
			padding: 8px;
			height: 35px;
			background-color: #314564;
		}
	}
	.card-item__iframe {
		grid-template-columns: repeat(1, 1fr) !important;
	}
}
.card-item__iframes {
	display: grid;
	gap: 25px;
	grid-template-columns: repeat(2, 1fr);
	& > div {
		border-radius: 10px;
		overflow: hidden;
		height: 130px;
		iframe {
			height: 100%;
			width: 100%;
		}
	}
}
</style>

